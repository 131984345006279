angular.module("labApp").factory("NewResultsHelpersFactory", [function () {
    var helperFunctions = {

        checkDefaultForNewResults: function (parameter) {
            var defaultValue = null;
            if (parameter.hasOwnProperty('default_num')) {
                defaultValue = parameter.default_num;
            } else if (parameter.hasOwnProperty('default_str')) {
                defaultValue = parameter.default_str;
            }
            return defaultValue;
        },

        checkDefaultForEditingResults: function(parameter, results) {
            var value = null;
            if (results.hasOwnProperty(parameter._id.$oid)) {
                if (parameter.value_type !== 'DAT') {
                    value = results[parameter._id.$oid]
                } else if (parameter.value_type === 'DAT') {
                    if (results[parameter._id.$oid] !== "") {
                        value = new Date(results[parameter._id.$oid])
                    }
                }
            }
            return value;
        },

        checkDefault: function (parameter, type, results) {
            if(type==="new") {
                return helperFunctions.checkDefaultForNewResults(parameter);
            }
            if(type==="edit") {
                return helperFunctions.checkDefaultForEditingResults(parameter, results);
            }
        },

        createForm: function (param_list, type, results) {
            var fieldgroupWrapper = [
                {
                    className: "layout-row layout-wrap",
                    fieldGroup: [

                    ]
                }
            ];
            param_list.forEach(function (param_element, index, param_array) {
                if (!param_element.hasOwnProperty('unit')) {
                    param_element.unit = {unit_name: 'no units'}
                }
                if (param_element.value_type === 'STR') {
                    param_element.field_type = 'input';
                    param_element.templateOptions = {
                        label: param_element.form_label,
                        required: param_element.required
                    }
                }
                else if (param_element.value_type === 'NUM') {
                    param_element.field_type = 'input';
                    param_element.templateOptions = {
                        type: 'number',
                        label: param_element.form_label + ' (' + param_element.unit.unit_name + ')',
                        required: param_element.required
                    }
                }
                else if (param_element.value_type === 'DAT') {
                    param_element.field_type = 'datepicker';
                    param_element.templateOptions = {
                        required: param_element.required,
                        placeholder: "dd/mm/yyyy",
                        label: param_element.form_label
                    }
                }
                else if (param_element.value_type === 'TIM') {
                    param_element.field_type = 'timepicker';
                    param_element.templateOptions = {
                        required: param_element.required
                    }
                }
                else {
                    param_element.value_type = 'input';
                    param_element.templateOptions = {
                        label: param_element.form_label + ' (' + param_element.unit.unit_name + ')',
                        required: param_element.required
                    }
                }

                var paramFormField = {
                    key: param_element.key,
                    className: "flex-25",
                    type: param_element.field_type,
                    defaultValue: helperFunctions.checkDefault(param_element, type, results),
                    templateOptions: param_element.templateOptions
                };
                fieldgroupWrapper[0].fieldGroup.push(paramFormField);
                }
            );
            return fieldgroupWrapper;
        },

        createSubmission: function (params_list, formModel, createResult) {
            params_list.forEach(function (param_element, index, param_array) {
                if (formModel[param_element.key] === "") {
                    formModel[param_element.key] = null
                }
                createResult.assessment_values[index] = {
                    param_name: param_element.form_label,
                    assessment_param_slug: param_element.assessment_param_slug,
                    value_type: param_element.value_type,
                    value: formModel[param_element.key]
                }
                if (param_element.value_type === "DAT" && formModel[param_element.key] !== null) {
                    createResult.assessment_values[index].value = helperFunctions.formatDateForSaving(formModel[param_element.key])
                }
            });
            return createResult
        },

        formatDateForSaving: function (dateString) {
            return moment(dateString, 'DD MMMM YYYY').format('YYYY-MM-DD');
        }

    }
    return helperFunctions
}]);

