angular.module('KisanSatApp').service('SatelliteDetailDialogService', function($mdDialog) {
   this.show = function(scope, field_slug, object_slug, process_name) {
        $mdDialog.show({
            templateUrl: '/static/assets/khapps/ksat/satellite-detail.dialog.html',
            controller: 'SatelliteDetailViewController',
            parent: angular.element(document.body),
            animation: true,
            scope: scope,
            preserveScope: true,
            clickOutsideToClose: false,
            locals: {
                object_slug: object_slug,
                process_name: process_name,
                parentControllerData: {"field_slug": field_slug},
            }
        });
   }
});
