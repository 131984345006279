angular.module("labApp").factory("ReportingParameterHelpers", [function () {
    var helperFunctions = {

        checkDefault: function (parameter) {
            var defaultValue = null
            if (parameter.hasOwnProperty('default_num')) {
                defaultValue = parameter.default_num;
            } else if (parameter.hasOwnProperty('default_str')) {
                defaultValue = parameter.default_str;
            } else {
                defaultValue = "";
            }
            return defaultValue;
        },

        convert_param: function (element, param_name, representationString, params) {
            if (element.hasOwnProperty(param_name)) {
                representationString += ' x ' + helperFunctions.extractParamName(element[param_name], params);
            } else {
                representationString += ' ...';
            }
            return representationString;
        },

        extractParamName: function (param_key, param_dicts) {
            var result = 'n/a (error finding parameter name)'
            angular.forEach(param_dicts, function (element, index) {
                if (element.value === param_key) {
                    result = element.name
                }
            });
            return result
        },

        convert_factor: function (element, param_name, representationString) {
            if (element.hasOwnProperty(param_name)) {
                representationString += element[param_name].toString();
            } else {
                representationString += '...';
            }
            return representationString;
        },

        modelRepresentation: function (form, params) {
            var representationString = '';
            angular.forEach(form, function (element, index) {
                representationString += '(';
                representationString = helperFunctions.convert_factor(element, 'factor_1', representationString);
                representationString = helperFunctions.convert_param(element, 'parameter_1', representationString, params);
                representationString += ')';

                if (element.hasOwnProperty('ratio') && element.ratio === true) {
                    representationString += ' / (';
                    representationString = helperFunctions.convert_factor(element, 'factor_2', representationString);
                    representationString = helperFunctions.convert_param(element, 'parameter_2', representationString, params);
                    representationString += ')';
                }

                if (element.hasOwnProperty('operator') && element.operator != 'null') {
                    representationString += '\n ' + element.operator + '\n '
                }

            });
            return representationString;
        },

        checkNoEndingOperator: function (formArray) {
            // Disables form submission if returns true
            if (formArray) {
                var finalElement = formArray.slice(-1)[0];
                if (finalElement.hasOwnProperty('operator')) {
                    if (finalElement.operator !== 'null') {
                        return true
                    } else {
                        return false
                    }
                }
            }
        },

        extractNumericOptions: function (input_data, property_dict) {
            var optionsArray = [];
            angular.forEach(input_data, function (element, index) {
                if (element.hasOwnProperty(property_dict.name) && element.hasOwnProperty(property_dict.value) && element.value_type === "NUM") {
                    optionsArray.push({
                        name: element[property_dict.name],
                        value: element[property_dict.value]
                    })
                }
            });
            return optionsArray;
        },

        extractOptions: function (input_data, property_dict) {
            var optionsArray = [];
            angular.forEach(input_data, function (element, index) {
                if (element.hasOwnProperty(property_dict.name) && element.hasOwnProperty(property_dict.value)) {
                    optionsArray.push({
                        name: element[property_dict.name],
                        value: element[property_dict.value]
                    })
                }
            });
            return optionsArray;
        },

        extractProcessors: function (input_data, property_dict) {
            var optionsArray = [];
            angular.forEach(input_data, function (element, index) {
                optionsArray.push({
                    name: element.properties[property_dict.name],
                    value: element.properties[property_dict.value]
                })
            })
            optionsArray.push({name: '***Default Specification***', value: null});
            return optionsArray;
        },

        prepareModelForSaving: function (saveModel, numericParams, configurator_slug) {
            saveModel.assessment_configurator = configurator_slug;
            saveModel.formula_string = helperFunctions.modelRepresentation(saveModel.formula_definition, numericParams);
            angular.forEach(saveModel.formula_definition, function (element, index) {
                if (element.operator === 'null') {
                    element.operator = null;
                }
                if (!element.hasOwnProperty('factor_2')) {
                    element.factor_2 = null;
                }
                if (!element.hasOwnProperty('parameter_2')) {
                    element.parameter_2 = null;
                }
            });

            return saveModel
        },

        // TODO: test change
        prepareSpecificationForSaving: function (saveModel, configurator_slug) {
            saveModel.assessment_configurator = configurator_slug;
            delete saveModel.configurator_slug;
            if (!saveModel.hasOwnProperty('customer')) {
                saveModel.customer = null;
            }
            if (!saveModel.hasOwnProperty('crop_destination')) {
                saveModel.crop_destination = null;
            }
            return saveModel
        }

    };
    return helperFunctions
}]);

