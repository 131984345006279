angular.module("labApp").factory("ModelAttributeFilterAdapterFactory", ["$resource", function ($resource) {
    return $resource('api/v1.0/notification/distributionlist/modelattributefilteradapter/:modelattributefilteradapter_slug/', {modelattributefilteradapter_slug: '@modelattributefilteradapter_slug'}, {
        query: {
            isArray: true
        },
        save: {
            method: 'POST'
        },
        update: {
            params: {modelattributefilteradapter_slug: '@modelattributefilteradapter_slug'},
            url: '/api/v1.0/notification/distributionlist/modelattributefilteradapter/:modelattributefilteradapter_slug/',
            method: 'PUT'
        },
        get: {
            params: {
                harvest_assessment_slug: '@harvest_assessment_slug',
                modelattributefilteradapter_slug: '@modelattributefilteradapter_slug',
                key: '@key',
                value: '@value',
                data_store_name: '@data_store_name',
                label: '@label'
            },
            url: '/api/v1.0/notification/distributionlist/modelattributefilteradapter/:modelattributefilteradapter_slug/',
            method: 'GET'
        }
    });
}]);