import * as Highcharts from 'highcharts';
import HC_more from 'highcharts/highcharts-more';
HC_more(Highcharts);

angular.module('KisanSatApp')
    .controller('SatelliteDetailViewController',
        function SatelliteDetailViewController($scope, $rootScope, $filter, $khMessage, $mdDialog, $http, $window, leafletData,
                                               GenericMapHelper, SatellitePublishedDatasetResource,
                                               FarmFieldResource, object_slug, process_name,
                                               parentControllerData, GenericMapService, GenericUtilsService) {
            var vm = $scope;
            var mapid = 'detailMap';
            var area_stats_column_chart_data_multiple_dates = [];
            var user_selected_layers = [];
            var AppHelpers = appHelpers();
            $scope.fieldvalue = GenericMapService.get_field_Feature($rootScope, parentControllerData.field_slug, 'field_slug');
            $scope.fieldslugvalue = parentControllerData.field_slug;

            function select_latest_scene(process_name) {
                $scope.show_field_overlay_layer_list_EmptyState = true;
                if (angular.isUndefined(object_slug) && !angular.equals($scope.layers['overlays'], {})) {
                    for (var prop in $scope.layers['overlays']) {
                        if ($scope.layers['overlays'][prop].field_slug === parentControllerData.field_slug && $scope.layers['overlays'][prop].is_uav === false && $scope.layers['overlays'][prop].group === process_name) {
                            $scope.show_field_overlay_layer_list_EmptyState = false;
                            object_slug = $scope.layers['overlays'][prop].slug;
                            process_name = $scope.layers['overlays'][prop].group;
                            $scope.layers['overlays'][prop].visible = true;
                            vm.selected_overlay_item = object_slug;
                            vm.height = 12;
                            vm.changeSelectedLayer($scope.layers['overlays'][prop].slug, $scope.layers['overlays'][prop].group, $scope.layers['overlays'][prop].acquisition_date, $scope.layers['overlays'][prop].visible);
                            break;
                        }
                    }
                }
            }

            vm.dialog_header_footer_functions = {
                isDialogEditable: false,
                isContentDownloadable: false,
                isExportableAsExcel: false,
                isExportableAsPDF: false,
                isExportableAsDocument: false,
                isExportableAsWord: false,
                isExportableAsAttachment: false,
                isSharableByEmail: false,
                isSharableByTwitter: false,
            };

            // var template = {
            //     templatePath: '/static/assets/khapps/ksat/SatelliteDetailViewDialog.html',
            //     title: 'Satellite based Field Monitor: ' + $scope.fieldvalue.properties.field_name + ' (' + $scope.fieldvalue.properties.field_area + ' ha)',
            //     ariaLable: "satBook.title"
            // };
            // vm.dialogDict = template;
            vm.dialogTitle = 'Satellite based Field Monitor: ' + $scope.fieldvalue.properties.field_name + ' (' + $scope.fieldvalue.properties.field_area + ' ha)';
            vm.currentView = 'chartView';
            vm.userSettingDateFormat = $rootScope.userSettingDateFormat;

            vm.pieChartConfig = {
                options: {
                    exporting: {
                        enabled: false
                    },
                    lang: {
                        noData: 'Loading Data...'
                    },
                    chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        type: 'pie'
                    },
                    tooltip: {
                        shared: true
                    },
                },
                tooltip: {
                    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                },
                legend: {
                    align: 'center',
                    verticalAlign: 'top',
                    floating: true,
                    borderColor: '#CCC',
                    borderWidth: 1,
                    shadow: false
                },
                title: {
                    text: '',
                    style: {
                        display: 'none'
                    }
                },
                credits: {
                    enabled: false
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: true,
                            format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                        }
                    }
                },
                series: vm.pie_series_data
            };

            vm.columnChartConfig = {
                options: {
                    exporting: {
                        enabled: false
                    },
                    lang: {
                        noData: 'Loading Data...'
                    },
                    chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        type: 'column'
                    },
                    tooltip: {
                        shared: true
                    },
                },
                tooltip: {
                    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                },
                title: {
                    text: 'Percent Field Area within Categories',
                },
                credits: {
                    enabled: false
                },
                xAxis: {
                    title: {
                        text: 'Category Bands'
                    },
                    categories: []
                },
                yAxis: {
                    title: {
                        text: 'Percent Area'
                    },
                    max: 100
                },
                plotOptions: {
                    column: {
                        dataLabels: {
                            enabled: true
                        },
                        enableMouseTracking: false
                    }
                },
                series: []
            };

            vm.lineChartConfig = {
                options: {
                    exporting: {
                        enabled: false
                    },
                    lang: {
                        noData: 'Loading Data...'
                    },
                    chart: {
                        height: 200
                    },
                },
                chart: {
                    height: 50
                },
                title: {
                    text: vm.field_progress_chart_title
                },
                credits: {
                    enabled: false
                },
                xAxis: {
                    type: 'datetime',
                    title: {
                        text: vm.field_progress_chart_x_axis_title
                    }
                },
                yAxis: {
                    title: {
                        text: vm.field_progress_chart_y_axis_title
                    },
                    min: 0
                },
                tooltip: {
                    crosshairs: true,
                    shared: true,
                    valueSuffix: ''
                },
                plotOptions: {
                    spline: {
                        marker: {
                            radius: 4,
                            lineColor: '#666666',
                            lineWidth: 1
                        }
                    }
                },
                legend: {
                    style: {
                        display: 'none'
                    }
                },
                series: []
            };

            vm.filterFunction = function () {
                return function (items, field, reverse) {
                    var filtered = [];
                    angular.forEach(items, function (item) {
                        filtered.push(item);
                    });
                    filtered.sort(function (a, b) {
                        return (a[field] > b[field] ? 1 : -1);
                    });
                    if (reverse) filtered.reverse();
                    return filtered;
                };
            };

            function create_field_progress_chart(process_name) {
                var overlayLayers = $scope.layers['overlays'];
                var ranges = [];
                var averages = [];
                for (var prop in overlayLayers) {
                    var overlay_layer = overlayLayers[prop];
                    if (angular.isDefined(overlay_layer.metadata)) {
                        if ($scope.fieldslugvalue === overlay_layer.metadata.field_slug && overlay_layer.metadata.statistics !== null && overlay_layer.metadata.service.processing_type.display_name === process_name) {
                            ranges.push([overlay_layer.metadata.acquisition_timestamp, overlay_layer.metadata.statistics.min_value, overlay_layer.metadata.statistics.max_value]);
                            averages.push([overlay_layer.metadata.acquisition_timestamp, overlay_layer.metadata.statistics.average_value]);
                            vm.process_unit = overlay_layer.metadata.service.processing_type.unit;
                        }
                    }
                }
                vm.field_progress_chart_title = 'Field Progress Over Time';
                vm.field_progress_chart_x_axis_title = 'Date';
                vm.field_progress_chart_y_axis_title = process_name;
                if (vm.process_unit)
                    vm.field_progress_chart_y_axis_title = vm.field_progress_chart_y_axis_title + ' (' + vm.process_unit + ')';
                var field_progress_line_chart_data = [
                    {
                        showInLegend: false,
                        name: 'Average ' + process_name,
                        data: averages,
                        zIndex: 1,
                        type: 'spline',
                        marker: {
                            radius: 4,
                            fillColor: 'white',
                            lineWidth: 2,
                            lineColor: '#666666' //Highcharts.getOptions().colors[0]
                        }
                    },
                    {
                        name: 'Range',
                        data: ranges,
                        type: 'areasplinerange',
                        lineWidth: 0,
                        linkedTo: ':previous',
                        color: Highcharts.getOptions().colors[0],
                        fillOpacity: 0.3,
                        zIndex: 0,
                        marker: {
                            enabled: false
                        }
                    }
                ];
                vm.lineChartConfig.series = field_progress_line_chart_data;
            }

            function load_layer_information(object_slug) {
                SatellitePublishedDatasetResource.get({publish_slug: object_slug}, function (result_data) {
                    vm.satelliteLayerInformation = result_data.data;
                    var area_stats = vm.satelliteLayerInformation.areastatistics_set;
                    var pie_chart_data_holder = {};

                    if (angular.isDefined(area_stats)) {
                        area_stats.forEach(function (d) {
                            if (pie_chart_data_holder.hasOwnProperty(d.class_value)) {
                                pie_chart_data_holder[d.class_value] = pie_chart_data_holder[d.class_value] + parseFloat(d.percent_value);
                            } else {
                                pie_chart_data_holder[d.class_value] = parseFloat(d.percent_value);
                            }
                        });


                        var area_stats_pie_chart_data = [];
                        for (var prop in pie_chart_data_holder) {
                            area_stats_pie_chart_data.push({name: prop, y: pie_chart_data_holder[prop]});
                        }
                        var pie_series_data = [{
                            name: 'Percent Area',
                            data: area_stats_pie_chart_data
                        }];
                        vm.pieChartConfig.series = pie_series_data;
                        var xAxisCategories = [];
                        var column_data = [];
                        area_stats.forEach(function (d) {
                            xAxisCategories.push(d.upper_band);
                            column_data.push({y: parseFloat(d.percent_value)});
                        });
                        var area_stats_column_chart_data = {
                            showInLegend: true,
                            id: object_slug,
                            index: vm.satelliteLayerInformation.acquisition_timestamp,
                            name: vm.satelliteLayerInformation.acquisition_date,
                            data: column_data
                        };
                        area_stats_column_chart_data_multiple_dates.push(area_stats_column_chart_data);
                        area_stats_column_chart_data_multiple_dates = _.sortBy(area_stats_column_chart_data_multiple_dates, 'index');
                        vm.columnChartConfig.series = area_stats_column_chart_data_multiple_dates;
                        vm.columnChartConfig.xAxis.categories = xAxisCategories;
                    }

                }, function (error) {
                    GenericUtilsService.handleError($filter, $khMessage, error);
                });
            }

            function loadMapData() {
                $scope.showEmptyState = true;
                $scope.fieldDiaryFilters = {};
                var field_slug = parentControllerData.field_slug;
                $scope.noFieldSatelliteImages = true;
                var format_json = 'json';
                var queryParams = {format: format_json, field_slug: field_slug};
                SatellitePublishedDatasetResource.query(queryParams, function (result_data) {
                    if (result_data.count > 0) {
                        $scope.noFieldSatelliteImages = false;
                    }
                });
                FarmFieldResource.get({'field_slug': field_slug, 'content_type': 'geojson'}, function (data) {

                    $scope.field_summary_data = data;
                    $scope.field_header_name = $scope.field_summary_data.field_name;
                    $rootScope.cropbook_plotdata_geoJson = data;
                    leafletData.getMap(mapid).then(function (map) {
                        $rootScope.satbook_fielddata_geoJsonLayer = createGeoJsonFieldPolygonLayer(data, $rootScope, leafletData, mapid);
                        $rootScope.satbook_leaflet_field_layer_id = $rootScope.satbook_fielddata_geoJsonLayer._leaflet_id;
                        map.addLayer($rootScope.satbook_fielddata_geoJsonLayer);
                        map.fitBounds($rootScope.satbook_fielddata_geoJsonLayer.getBounds(), {padding: [100, 100]});
                        $rootScope.satbook_fielddata_geoJsonLayer.bringToFront();
                    });
                });

                function createGeoJsonFieldPolygonLayer(result, $rootScope, leafletData, mapid) {
                    var getStyle = {
                        "weight": 2,
                        "opacity": 1,
                        "stroke": "black",
                        "dashArray": 10,
                        "fillOpacity": 0.1
                    };

                    return (L.geoJson(result, {
                        name: 'Field',
                        layer: 'FieldLayer',
                        group: 'FarmField',
                        onEachFeature: onEachFeature,
                        style: getStyle
                    }));


                    function onEachFeature(feature, layer) {
                        layer.on({click: zoomToFeature});
                    }

                    function zoomToFeature(event, args) {
                        leafletData.getMap(mapid).then(function (map) {
                            map.fitBounds(event.target.getBounds(), {padding: [100, 100]});
                            $rootScope.selectedFeature = event.target;
                            $rootScope.originalFeature = event.target;
                            $rootScope.$broadcast("changedSelectedFeature");
                        });
                    }
                }
            }

            $scope.show_field_overlay_layer_list_EmptyState = true;
            if (angular.isUndefined(object_slug) && !angular.equals($scope.layers['overlays'], {})) {
                for (var prop in $scope.layers['overlays']) {
                    if ($scope.layers['overlays'][prop].field_slug === parentControllerData.field_slug && $scope.layers['overlays'][prop].is_uav === false) {
                        $scope.show_field_overlay_layer_list_EmptyState = false;
                        object_slug = $scope.layers['overlays'][prop].slug;
                        process_name = $scope.layers['overlays'][prop].group;
                        $scope.layers['overlays'][prop].visible = true;
                        vm.selected_overlay_item = object_slug;
                        vm.height = 12;
                        user_selected_layers.push({
                            selection_visibility: $scope.layers['overlays'][prop].visible,
                            selection_id: $scope.layers['overlays'][prop].slug,
                            selection_date: $scope.layers['overlays'][prop].acquisition_date
                        });
                        // changeSelectedLayer($scope.layers['overlays'][prop].slug, $scope.layers['overlays'][prop].group, $scope.layers['overlays'][prop].acquisition_date, $scope.layers['overlays'][prop].visible);
                        break;
                    }
                }
            }

            load_layer_information(object_slug);

            create_field_progress_chart(process_name);

            loadMapData();

            vm.getDisableStatus = function (new_object_slug, new_process_name, new_object_date, visibility) {
                if (user_selected_layers.length === 1 && user_selected_layers[0].selection_id === new_object_slug) {
                    return true;
                }
                else {
                    return false;
                }
            };

            function changeSelectedLayer(new_object_slug, new_process_name, new_object_date, visibility) {
                if (process_name !== new_process_name) {
                    process_name = new_process_name;
                    area_stats_column_chart_data_multiple_dates = [];
                    create_field_progress_chart(process_name);
                }
                if (visibility === false) {
                    user_selected_layers.push({
                        selection_visibility: visibility,
                        selection_id: new_object_slug,
                        selection_date: new_object_date
                    });
                    object_slug = new_object_slug;
                    process_name = new_process_name;
                    load_layer_information(object_slug, process_name);
                    vm.selected_overlay_item = object_slug;
                    vm.height = 24;
                }
                else {
                    user_selected_layers = _.reject(user_selected_layers, function (el) {
                        return el.selection_id === new_object_slug;
                    });
                    area_stats_column_chart_data_multiple_dates = _.reject(area_stats_column_chart_data_multiple_dates, function (el) {
                        return el.id === new_object_slug;
                    });
                    area_stats_column_chart_data_multiple_dates = _.sortBy(area_stats_column_chart_data_multiple_dates, 'index');
                    vm.columnChartConfig.series = area_stats_column_chart_data_multiple_dates;
                }
            }

            vm.changeSelectedLayer = changeSelectedLayer;

            vm.onTabChanges = function (new_process_name) {
                console.log('On Tab Changes');
                console.log(new_process_name);
                process_name = new_process_name;
                console.log('user_selected_layers');
                console.log(user_selected_layers);
                console.log('area_stats_column_chart_data_multiple_dates');
                console.log(area_stats_column_chart_data_multiple_dates);
                area_stats_column_chart_data_multiple_dates = [];
                create_field_progress_chart(process_name);
                console.log('Select Latest scene process');
                select_latest_scene(process_name);
            };

            $scope.ImageSrc = '';
            $scope.openModalImage = function (image_file_path) {
                $scope.ImageSrc = image_file_path;
                $mdDialog.show({
                    templateUrl: '/static/assets/khapps/ksat/modal_image_dialog.html',
                    skipHide: true,
                    multiple: true,
                    locals: {
                        imageSrcToUse: image_file_path,
                        dialogTitle: 'Satellite Image'
                    },
                    controller: [
                        "$scope", "imageSrcToUse",
                        function ($scope, imageSrcToUse) {
                            $scope.ImageSrc = imageSrcToUse;
                            $scope.dialogTitle = vm.dialogTitle;
                        }
                    ]
                });
            };

            $scope.deleteSatelliteLayer = function (object_slug) {
                var subscriptionDict = {
                    "published_dataset_slug": object_slug
                };

                $mdDialog.show({
                    controller: "SatellitePublishedDatasetDeleteController",
                    templateUrl: '/static/assets/khapps/ksat/SatellitePublishedDatasetDeleteReasonsForm.html',
                    parent: angular.element(document.getElementById('SatelliteDetailViewDialog')),
                    // animation: true,
                    scope: $scope,
                    skipHide: true,
                    multiple: true,
                    preserveScope: true,
                    clickOutsideToClose: false,
                    locals: {subscription_dict: subscriptionDict}
                });
            };

            function appHelpers() {
                return {
                    load_satellite_delete_service: function (delete_options) {
                        return [
                            {
                                className: 'flex-100',
                                type: 'radio',
                                key: 'delete_reason_slug',
                                id: 'service_id',
                                templateOptions: {
                                    required: true,
                                    label: 'Select delete reason',
                                    placeholder: 'Select delete reason',
                                    valueProp: 'slug',
                                    labelProp: 'delete_resason',
                                    options: delete_options
                                }
                            }
                        ];
                    },
                };
            }
        });


