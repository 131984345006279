(function (angular) {
    "use strict";
    angular.module('labApp')
        .factory('FarmListFactory', Resource);

    Resource.$inject = ['$resource', 'GenericResourceInterface'];

    function Resource($resource, GenericResourceInterface) {
        //////////////////////////////////////
        // Variable declarations
        //////////////////////////////////////
        // List of fields to extract from input data as valid query parameters
        const IDENTIFIER_KEY = "slug";
        const FILTER_FIELDS = {as_dict: "as_dict"};

        //////////////////////////////////////
        // API Resource
        //////////////////////////////////////
        var url = "api/v1.0/khutils/farms/";
        const RESOURCE = $resource(
            url, {slug: '@slug'},
            {
                query: {
                    isArray: true
                }
            }
        );

        return new GenericResourceInterface(RESOURCE, FILTER_FIELDS, IDENTIFIER_KEY);

    }

})(angular);
