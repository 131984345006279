(function () {

    'use strict';

    angular.module("labApp").controller('AssessmentDetailController', [
        '$scope',
        '$rootScope',
        '$routeParams',
        '$location',
        'AssessmentFactory',
        'ReportingParametersFactory',
        'AssessmentSpecificationFactory',
        'NgTableParams',
        'LabFormHelpers',
        '$mdDialog',
        '$filter',
        function ($scope,
                  $rootScope,
                  $routeParams,
                  $location,
                  AssessmentFactory,
                  ReportingParametersFactory,
                  AssessmentSpecificationFactory,
                  NgTableParams,
                  LabFormHelpers,
                  $mdDialog,
                  $filter) {

            $rootScope.page_id = "labanalytics";
            $rootScope.sub_app_page_id = "labanalytics_setup";
            $rootScope.currentNavItem = null;
            $scope.ac_slug = $routeParams.assessment_config_slug;

            getAssessment();
            getReportingParameters();
            getAssessmentSpecifications();

            $scope.AssessmentDetailCards = [
                {
                    card_title: '',
                    kh_card_content_template: '/static/assets/khapps/lab/assessments/assessment_detail/assessment_detail_navigation.html',
                    card_flex: 100
                },
                {
                    card_title: $filter('translate')('title_assessment_summary'),
                    kh_card_content_template: '/static/assets/khapps/lab/assessments/assessment_detail/assessment_detail_summary.html',
                    card_flex: 100
                },
                {
                    card_title: $filter('translate')('title_assessment_specifications'),
                    kh_card_toolbar_filters_template: '/static/assets/khapps/lab/assessments/assessment_detail/assessment_detail_spec_toolbar.html',
                    kh_card_content_template: '/static/assets/khapps/lab/assessments/assessment_detail/assessment_detail_specifications.html',
                    card_flex: 100
                },
                {
                    card_title: $filter('translate')('title_assessment_parameters'),
                    kh_card_content_template: '/static/assets/khapps/lab/assessments/assessment_detail/assessment_detail_assessment_parameters.html',
                    card_flex: 50
                },
                {
                    card_title: $filter('translate')('title_reporting_parameters'),
                    kh_card_toolbar_filters_template: '/static/assets/khapps/lab/assessments/assessment_detail/assessment_detail_rp_toolbar.html',
                    kh_card_content_template: '/static/assets/khapps/lab/assessments/assessment_detail/assessment_detail_reporting_parameters.html',
                    card_flex: 50
                }
            ];

            $scope.searchAssParam = {
                form_label: {
                    id: 'text',
                    placeholder: $filter('translate')('filter_by_parameter_name')
                }
            };
            $scope.searchRepParam = {
                name: {
                    id: 'text',
                    placeholder: $filter('translate')('filter_by_parameter_name')
                }
            };
            $scope.searchValueType = {
                value_type: {
                    id: 'text',
                    placeholder: $filter('translate')('filter_by_value_type')
                }
            };
            $scope.searchRequired = {
                required: {
                    id: 'text',
                    placeholder: $filter('translate')('filter_by_reqiured_status')
                }
            };


            $scope.showRpForm = function () {
                $mdDialog.show({
                    templateUrl: '/static/assets/khapps/lab/assessments/reporting_parameter/reporting_parameter_form.html',
                    controller: 'RepParameterFormController',
                    scope: $scope,
                    preserveScope: true,
                    locals: {
                        ac_slug: $scope.ac_slug
                    }
                }).then(function (data) {
                });
            };

            $scope.showSpecForm = function () {
                $mdDialog.show({
                    templateUrl: '/static/assets/khapps/lab/assessments/assessment_specification/assessment_specification_form.html',
                    controller: 'AssessmentSpecificationFormController',
                    scope: $scope,
                    preserveScope: true,
                    locals: {
                        ac_slug: $scope.ac_slug
                    }
                }).then(function (data) {
                });
            };

            function redirectToAssessmentList() {
                $location.path('/assessments/');
            }

            var rpSaved = $scope.$on('reportingParamSaved', function (event, data) {
                $scope.reporting_params.push(data);
                loadTableData('reportingParams', $scope.reporting_params, NgTableParams);
                $scope.reporting_param_count += 1;//remove the count attribute when refactoring.
            });
            var specSaved = $scope.$on('specificationSaved', function (event, data) {
                $scope.specifications.push(data);
                loadTableData('specificationsTable', $scope.specifications, NgTableParams)
            });
            var updatedFilterValues = $scope.$on('filter:selections_updated', function () {
                redirectToAssessmentList()
            });
            $scope.$on('$destroy', function () {
                updatedFilterValues();
                rpSaved();
                specSaved();
            });

            function getAssessment(){
                AssessmentFactory.get(
                    $scope.ac_slug,
                    {},
                    function (result) {
                        $scope.assessment_config = result;
                        $scope.assessment_params = result.parameters_ref_list;
                        loadTableData('assessmentParams', $scope.assessment_params, NgTableParams);
                        $scope.assessment_param_count = $scope.assessment_params.length;
                    },
                    function (error) {
                        console.log("Error: ", error);
                    }
                );
            }

            function getReportingParameters() {
                ReportingParametersFactory.query({assessment_configurator: $scope.ac_slug}, function (result) {
                    $scope.reporting_params = result;
                    loadTableData('reportingParams', $scope.reporting_params, NgTableParams);
                    $scope.reporting_param_count = $scope.reporting_params.length;
                    $scope.rParamData = true;
                });
            }
            function getAssessmentSpecifications() {
                AssessmentSpecificationFactory.query({assessment: $scope.ac_slug}, function (success) {
                    $scope.specifications = success;
                    loadTableData('specificationsTable', $scope.specifications, NgTableParams)
                }, function (error) {
                    console.log(error)
                });
            }

            function loadTableData(scopeObject, data, NgTableParams) {
                $scope[scopeObject] = new NgTableParams({}, {dataset: data});
            }

            function extractDeletedParam(table_params, param_slug) {
                var removeIndex = table_params.map(function (element) {
                    return element.reporting_parameter_slug
                }).indexOf(param_slug);
                ~removeIndex && table_params.splice(removeIndex, 1);
                loadTableData('reportingParams', $scope.reporting_params, NgTableParams);
            }

            function extractDeletedSpecification(table_specs, spec_slug) {
                var removeIndex = table_specs.map(function (element) {
                    return element.specification_slug
                }).indexOf(spec_slug);
                ~removeIndex && table_specs.splice(removeIndex, 1);
                loadTableData('specificationsTable', $scope.specifications, NgTableParams)
            }

            $scope.deleteParam = function (param_slug, reporting_params) {
                ReportingParametersFactory.remove(
                    {
                        assessment_configurator: $scope.ac_slug,
                        rp_slug: param_slug
                    },
                    function (success) {
                        extractDeletedParam(reporting_params, param_slug);
                    },
                    function (error) {
                        console.log(error)
                    }
                )
            }
            $scope.deleteSpec = function (spec_slug, specifications) {
                AssessmentSpecificationFactory.remove(
                    {
                        assessment: $scope.ac_slug,
                        specification: spec_slug
                    },
                    function (success) {
                        extractDeletedSpecification(specifications, spec_slug);
                    },
                    function (error) {
                        console.log(error)
                    }
                )
            }

        }]);

})();