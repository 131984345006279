angular.module('labApp').service('GenericSampleService', function($resource, SelectedTeamService,
                                                            FarmFiltersService) {
    var resource = $resource("/api/v1.0/lab/samples/:slug/", {slug: '@slug'});

    var self=this;

    /**
     * Return a Promose for the given sample
     * @param slug - slug for sample
     * @return promise
     */
    self.get = function(slug) {
        var params = {
            slug: slug,
            team: SelectedTeamService.getSelectedSlug(),
            growing_season: FarmFiltersService.getGrowingSeason()
        };
        return resource.get(params).$promise;
    }
});
