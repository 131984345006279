angular.module('labApp').service('LabPermissionsService', function (LabAppPermissions) {

    var self = this;
    self.user_lab_permissions = {};
    self.promise = LabAppPermissions.get(function (result) {
        self.user_lab_permissions = result;
        self.showAddEdit = self.checkPermission('add_edit');
    }).$promise;

    self.checkPermission = function (permission) {
        if (permission === 'admin') {
            if (
                (self.user_lab_permissions.hasOwnProperty('superuser') &&
                    self.user_lab_permissions.superuser === true) ||
                (self.user_lab_permissions.hasOwnProperty('lab_admin') &&
                    self.user_lab_permissions.lab_admin === true)
            ) {
                return true
            } else {
                return false
            }
        }
        if (permission === 'team_or_app_admin') {
            if (
                (self.user_lab_permissions.hasOwnProperty('superuser') &&
                    self.user_lab_permissions.superuser === true) ||
                (self.user_lab_permissions.hasOwnProperty('team_admin') &&
                    self.user_lab_permissions.team_admin === true) ||
                (self.user_lab_permissions.hasOwnProperty('lab_admin') &&
                    self.user_lab_permissions.lab_admin === true)
            ) {
                return true
            } else {
                return false
            }
        }
        if (permission === 'add_edit') {
            if (
                (self.user_lab_permissions.hasOwnProperty('superuser') &&
                    self.user_lab_permissions.superuser === true) ||
                (self.user_lab_permissions.hasOwnProperty('lab_admin') &&
                    self.user_lab_permissions.lab_admin === true) ||
                (self.user_lab_permissions.hasOwnProperty('lab_add_edit') &&
                    self.user_lab_permissions.lab_add_edit === true)
            ) {
                return true
            } else {
                return false
            }
        }
        return false
    }
});
