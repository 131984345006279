angular.module("labApp").factory("ReportingParametersFactory", ["$resource", function ($resource) {
    return $resource('api/v1.0/lab/assessment/:assessment_configurator/reporting-parameters/:rp_slug/',
        {assessment_configurator: '@assessment_configurator', rp_slug: '@rp_slug'}, {
            get: {
                method: 'GET'
            },
            query: {
                method: 'GET',
                isArray: true
            },
            save: {
                method: 'POST',
                isArray: false
            },
            remove: {
                method: 'DELETE'
            }
        });
}]);