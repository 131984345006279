(function () {

    'use strict';

    angular.module("labApp").controller('AssessmentsShowController', ['$scope', '$rootScope', 'FilterParameters', 'AssessmentFactory', 'NgTableParams', '$mdDialog', function ($scope, $rootScope, FilterParameters, AssessmentFactory, NgTableParams, $mdDialog) {

        $rootScope.page_id="labanalytics";
        $rootScope.sub_app_page_id="labanalytics_setup";
        $rootScope.currentNavItem = 'assessments';
        updateAssessments();
        $scope.showAssessmentForm = showAssessmentForm;

        $scope.AssessmentListCards = [
            {
                card_title: 'Assessment Summary',
                kh_card_toolbar_filters_template: '/static/assets/khapps/lab/assessments/assessment_list/assessment_list_toolbar.html',
                kh_card_content_template: '/static/assets/khapps/lab/assessments/assessment_list/assessment_list.html',
                card_flex: 100
            }
        ];
        $scope.searchAssessmentName = {
            name: {
                id: 'text',
                placeholder: 'Filter by assessment name'
            }
        };
        $scope.searchAssessmentClassification = {
            assessment_classification: {
                id: 'text',
                placeholder: 'Filter by assessment classification'
            }
        };
        $scope.searchCrop = {
            crop_name: {
                id: 'text',
                placeholder: 'Filter by crop name'
            }
        };

        function showAssessmentForm() {
            $mdDialog.show({
                templateUrl: '/static/assets/khapps/lab/assessments/assessment_add_edit/AssessmentForm.html',
                controller: 'AssessmentAddEditController',
                scope: $scope,
                preserveScope: true,
                locals: {}
            }).then(function (data) {
            });
        }

        function loadTableData(scopeObject, data, NgTableParams) {
            $scope[scopeObject] = new NgTableParams({}, {dataset: data});
        }

        function updateAssessments() {
            var queryParams = FilterParameters.getFilterParameters($rootScope, ["team"]);
            AssessmentFactory.query(
                queryParams,
                function (result) {
                    $scope.assessments = result;
                    loadTableData('assessmentList', result, NgTableParams)
                }, function (error) {
                    console.log("Error: ", error);
                }
            );
        }

        var updatedFilterValues = $scope.$on('filter:selections_updated', function () {
            updateAssessments();
        });
        $scope.$on('$destroy', function () {
            updatedFilterValues();
        });

    }]);

})();
