angular.module('labApp').run(function ($http,
                                       $rootScope,
                                       $route,
                                       formlyConfig,
                                       TeamSettingsFactory,
                                       PlatformTerminologyFactory,
                                       LabPermissionsService) {

    $http.defaults.xsrfCookieName = "csrftoken";
    $http.defaults.xsrfHeaderName = "X-CSRFToken";

    $rootScope.in_app_add_button = '/static/assets/khapps/lab/secondary_navbar/lab_navbar_add_button.html';
    $rootScope.in_app_links_template = '/static/assets/khapps/lab/secondary_navbar/lab_navbar_links.html';
    $rootScope.view_as_template = "/static/assets/khapps/lab/secondary_navbar/lab_navbar_views.html";
    $rootScope.select_filters_template = "/static/assets/khapps/lab/secondary_navbar/lab_navbar_filters.html";

    $rootScope.lab_base_url = "/dashboard/lab/#";
    $rootScope.dashboard_url = "/dashboard/";

    $rootScope.platformTerminology = {};
    PlatformTerminologyFactory.get({app_name: 'lab-analytics'}, function (result) {
        $rootScope.platformTerminology.general = result.General;
        $rootScope.platformTerminology.labAnalytics = result.LabAnalytics;
        $rootScope.$broadcast("platformTerminology", result);
    })

    TeamSettingsFactory.get(function (result) {
        $rootScope.teamSettings = result;
    });

    $rootScope.permissions = LabPermissionsService;

    addRegExpForValidator('alNumHyphen', /^[a-zA-Z0-9-_]+$/)
    addDateValidator('pastDate', function (obj) {
        var now = new Date();
        var objDate = new Date(obj);
        return _.isDate(objDate) && objDate.getTime() < now.getTime();
    });

    function addRegExpForValidator(validatorName, validatorExpression) {
        var validators = {};
        validators[validatorName] = {
            expression: function (value) {
                return validatorExpression.test(value);
            },
            message: '"Invalid ' + validatorName + '"'
        }
        formlyConfig.setType({
            name: validatorName,
            defaultOptions: {
                validators: validators
            }
        })
    }

    function addDateValidator(validatorName, validatorExpression) {
        var validators = {};
        validators[validatorName] = {
            expression: validatorExpression,
            message: '"Invalid ' + validatorName + '"'
        }
        formlyConfig.setType({
            name: validatorName,
            defaultOptions: {
                validators: validators
            }
        })
    }

    //KH-datepicker
    formlyConfig.setType({
        name: 'khDatePicker',
        overwriteOk: true,
        templateUrl: "/static/assets/khapps2/base/khformelements/partials/kh_datepicker.html"
    });

    // Repeat Section
    formlyConfig.setType({
        name: 'repeatSection',
        templateUrl: 'repeatSection.html',
        controller: function ($scope) {
            var unique = 1;
            $scope.formOptions = {formState: $scope.formState};
            $scope.addNew = addNew;

            $scope.copyFields = copyFields;

            function copyFields(fields) {
                fields = angular.copy(fields);
                addRandomIds(fields);
                return fields;
            }

            function addNew() {
                $scope.model[$scope.options.key] = $scope.model[$scope.options.key] || [];
                var repeatsection = $scope.model[$scope.options.key];
                // Used to duplicate the values from the previous section into the new section:
                //var lastSection = repeatsection[repeatsection.length - 1];
                var newsection = {};
                //if (lastSection) {
                //    newsection = angular.copy(lastSection);
                //}
                repeatsection.push(newsection);
            }

            function addRandomIds(fields) {
                unique++;
                angular.forEach(fields, function (field, index) {
                    if (field.fieldGroup) {
                        addRandomIds(field.fieldGroup);
                        return; // fieldGroups don't need an ID
                    }

                    if (field.templateOptions && field.templateOptions.fields) {
                        addRandomIds(field.templateOptions.fields);
                    }

                    field.id = field.id || (field.key + '_' + index + '_' + unique + getRandomInt(0, 9999));
                });
            }

            function getRandomInt(min, max) {
                return Math.floor(Math.random() * (max - min)) + min;
            }
        }
    });

});
