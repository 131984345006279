require('angulartics');

export default angular.module("labApp", [
    'angulartics',
    require('angulartics-google-analytics'),
    "KhBase",
    "FmsApp",
    "ApiUtils.utilities",
    "FiltersData",
    "ngTableToCsv",
    "khFileUploads",
    "ApiUtils.khutils",
    "ApiUtils.lab"
]);
