// angular.module("labApp").factory("CropListFactory", ["$resource", function ($resource) {
//     return $resource('api/v1.0/fieldplotcrop/croplist/');
// }]);

(function (angular) {
    "use strict";
    angular.module('labApp').factory('CropListFactory', Resource);

    Resource.$inject = ['$resource', 'GenericResourceInterface'];

    function Resource($resource, GenericResourceInterface) {
        //////////////////////////////////////
        // Variable declarations
        //////////////////////////////////////
        // List of fields to extract from input data as valid query parameters
        const IDENTIFIER_KEY = "slug";
        const FILTER_FIELDS = {};

        //////////////////////////////////////
        // API Resource
        //////////////////////////////////////
        var url = "api/v1.0/fieldplotcrop/croplist/:slug";
        const RESOURCE = $resource(
            url, {slug: '@slug'},
            {
                query: {
                    isArray: true
                }
            }
        );

        return new GenericResourceInterface(RESOURCE, FILTER_FIELDS, IDENTIFIER_KEY);

    }

})(angular);
