(function () {

    'use strict';

    angular.module("labApp").controller('RepParameterFormController', [
        '$scope',
        '$location',
        '$routeParams',
        '$timeout',
        'AssessmentParameterFactory',
        'ReportingParameterHelpers',
        'AssessmentConfigParametersFactory',
        'ReportingParametersFactory',
        'LabFormHelpers',
        "ac_slug",
        '$mdDialog',
        '$khMessage',
        '$filter',
        function ($scope,
                  $location,
                  $routeParams,
                  $timeout,
                  AssessmentParameterFactory,
                  ReportingParameterHelpers,
                  AssessmentConfigParametersFactory,
                  ReportingParametersFactory,
                  LabFormHelpers,
                  ac_slug,
                  $mdDialog,
                  $khMessage,
                  $filter) {

            $scope.assessment_configurator = ac_slug;
            $scope.paramsReady = false;
            $scope.numericParams = [];

            $scope.onSumbit = onSubmit;
            $scope.closeDialog = closeDialog;
            $scope.modelRepresentation = ReportingParameterHelpers.modelRepresentation;
            $scope.checkNoEndingOperator = ReportingParameterHelpers.checkNoEndingOperator;
            getParameters($scope);

            function closeDialog() {
                $mdDialog.hide();
            }

            function onSubmit() {
                $scope.saveObject = ReportingParameterHelpers.prepareModelForSaving(
                    angular.copy($scope.paramModel),
                    $scope.numericParams,
                    $scope.assessment_configurator
                );
                ReportingParametersFactory.save(
                    $scope.saveObject,
                    function (success) {
                        $khMessage.show($filter('translate')('created_successfully'), 5000);
                        $mdDialog.hide();
                        $scope.paramModel = {};
                        $scope.$emit('reportingParamSaved', success.data)
                    }, function (error) {
                        $khMessage.show($filter('translate')('failed_to_create'), 5000);
                    }
                )
            }

            function getParameters(scope) {
                AssessmentConfigParametersFactory.get(
                    {slug: scope.assessment_configurator},
                    function (success) {
                        var property_names = {
                            name: 'form_label',
                            value: 'assessment_param_slug'
                        };
                        scope.numericParams = ReportingParameterHelpers.extractNumericOptions(success, property_names)
                        scope.paramsReady = true;
                    }, function (error) {
                        console.log("Error: ", error);
                    }
                );
            }

            $scope.$watch('paramsReady', function () {
                if ($scope.paramsReady === true) {
                    $scope.fields = fieldsFetcher($scope);
                    $scope.originalFields = angular.copy($scope.fields);
                    $scope.paramModel = {};
                    $scope.options = {};
                    $scope.onSubmit = onSubmit;
                }
            });


            function fieldsFetcher(scope) {
                return [{
                    key: 'formula_definition',
                    type: 'repeatSection',
                    templateOptions: {
                        btnText: 'Add a parameter (ratio) to the calculation',
                        fields: [{

                            className: 'layout-row',
                            fieldGroup: [
                                {

                                    className: 'flex',
                                    key: 'factor_1',
                                    type: 'input',
                                    templateOptions: {
                                        label: 'Multiplier applied to primary parameter',
                                        required: true,
                                        placeholder: 'eg. 1.0'
                                    }

                                }, {

                                    className: 'flex',
                                    key: 'parameter_1',
                                    type: 'searchable_select',
                                    templateOptions: {
                                        label: 'Primary parameter',
                                        required: true,
                                        options: scope.numericParams
                                    }

                                }, {

                                    className: 'flex',
                                    type: 'checkbox',
                                    key: 'ratio',
                                    templateOptions: {
                                        label: 'Want this parameter as a ratio against another parameter?'
                                    },
                                    watcher: {
                                        listener: function (field, newValue, oldValue, scope, stopWatching) {
                                            if (newValue === false) {
                                                if (scope.model.hasOwnProperty('parameter_2')) {
                                                    delete scope.model['parameter_2']
                                                }
                                                if (scope.model.hasOwnProperty('factor_2')) {
                                                    delete scope.model['factor_2']
                                                }
                                            }
                                        }
                                    }

                                }]
                        }, {
                            className: 'layout-row',
                            fieldGroup: [
                                {

                                    hideExpression: "!model.ratio",
                                    className: 'flex',
                                    key: 'factor_2',
                                    type: 'input',
                                    templateOptions: {
                                        label: 'Multiplier applied to division parameter',
                                        required: true,
                                        placeholder: 'eg. 1.0'
                                    },
                                    expressionProperties: {
                                        "templateOptions.required": "model.ratio",
                                        "templateOptions.disabled": "!model.ratio"
                                    }

                                }, {

                                    hideExpression: "!model.ratio",
                                    className: 'flex',
                                    key: 'parameter_2',
                                    type: 'searchable_select',
                                    templateOptions: {
                                        label: 'Parameter used for division',
                                        required: true,
                                        options: scope.numericParams
                                    },
                                    expressionProperties: {
                                        "templateOptions.required": "model.ratio",
                                        "templateOptions.disabled": "!model.ratio"
                                    }

                                }, {

                                    className: 'flex',
                                    key: 'operator',
                                    type: 'searchable_select',
                                    templateOptions: {
                                        label: 'Operator',
                                        required: true,
                                        options: [
                                            {name: '+', value: 'PLUS'},
                                            {name: '-', value: 'MINUS'},
                                            {name: 'x', value: 'MULTIPLY'},
                                            {name: '/', value: 'DIVIDE'},
                                            {name: 'End of formula', value: 'null'}
                                        ]
                                    },
                                    watcher: {
                                        listener: function (field, newValue, oldValue, scope, stopWatching) {
                                            if (newValue === 'null') {
                                                if (scope.model.hasOwnProperty('operator') && scope.model.operator == 'null') {
                                                    angular.forEach($scope.paramModel.formula_definition, function (subModel, index) {
                                                        if (subModel.operator === 'null') {
                                                            $scope.paramModel.formula_definition = $scope.paramModel.formula_definition.slice(0, index + 1)
                                                        }
                                                    })
                                                }
                                            }
                                        }
                                    }
                                }

                            ]
                        }]
                    }
                }
                ]
            }

        }
    ])
    ;

})();

