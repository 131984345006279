angular.module("labApp").factory("AssessmentSpecificationFiltersFactory", ["$resource", function ($resource) {
    return $resource('/api/v1.0/lab/assessment/:assessment/specifications/:specification/filters/:filter_slug',
        {
            assessment: '@assessment_configurator',
            specification: '@assessment_specification',
        },
        {
            query: {
                isArray: true
            },
            save: {
                method: 'POST'
            },
            update: {
                method: 'PUT'
            },
            get: {
                method: 'GET'
            },
            remove: {
                method: 'DELETE'
            }
        });
}]);




