(function () {

    'use strict';

    angular.module("labApp").controller('SpecificationFilterFormController', [
        '$scope',
        '$rootScope',
        '$location',
        '$routeParams',
        '$timeout',
        'AssessmentParameterFactory',
        'FieldStylingHelpersFactory',
        'ReportingParameterHelpers',
        'AssessmentConfigParametersFactory',
        'AssessmentSpecificationFiltersFactory',
        'ReportingParametersFactory',
        'LabFilterOperatorsFactory',
        'LabFormHelpers',
        '$khMessage',
        '$filter',
        '$mdDialog',
        function ($scope,
                  $rootScope,
                  $location,
                  $routeParams,
                  $timeout,
                  AssessmentParameterFactory,
                  FieldStylingHelpersFactory,
                  ReportingParameterHelpers,
                  AssessmentConfigParametersFactory,
                  AssessmentSpecificationFiltersFactory,
                  ReportingParametersFactory,
                  LabFilterOperatorsFactory,
                  LabFormHelpers,
                  $khMessage,
                  $filter,
                  $mdDialog) {

            $rootScope.page_id = "labanalytics";
            $rootScope.sub_app_page_id = "labanalytics_setup";
            $rootScope.currentNavItem = "assessments";

            $scope.styling = FieldStylingHelpersFactory.classes;
            $scope.paramsReady = false;
            $scope.operatorsReady = false;
            $scope.repParams = [];
            $scope.operators = [];

            $scope.onSumbit = onSubmit;
            $scope.closeDialog = closeDialog;
            $scope.modelRepresentation = ReportingParameterHelpers.modelRepresentation;
            $scope.checkNoEndingOperator = ReportingParameterHelpers.checkNoEndingOperator;
            $scope.checkAtLeastOneElement = function (model) {
                if (model.hasOwnProperty('formula_definition')) {
                    if (model.formula_definition.length > 0) {
                        return false;
                    }
                }
                return true;
            };
            getParameters($scope);
            getOperators($scope);

            function closeDialog() {
                $mdDialog.hide();
            }

            function onSubmit() {
                $scope.saveObject = ReportingParameterHelpers.prepareModelForSaving(
                    angular.copy($scope.filterModel),
                    $scope.repParams,
                    $scope.assessment_configurator
                );
                $scope.saveObject.assessment_specification = $scope.specification_slug;
                AssessmentSpecificationFiltersFactory.save($scope.saveObject, function (success) {
                        $khMessage.show($filter('translate')('created_successfully'), 5000);
                        LabFormHelpers.hideModals();
                        $scope.filterModel = {};
                        $scope.$emit('filterSaved', success.data)
                    }, function (error) {
                        $khMessage.show($filter('translate')('deleted_successfully'), 5000);
                    }
                )
            }

            function getParameters(scope) {
                ReportingParametersFactory.query(
                    {
                        assessment_configurator: scope.assessment_configurator
                    }, function (success) {
                        var property_names = {
                            name: 'name',
                            value: 'reporting_parameter_slug'
                        };
                        scope.repParams = ReportingParameterHelpers.extractOptions(success, property_names)
                        scope.paramsReady = true;
                    }
                );
            }

            $scope.$watch('paramsReady', function () {
                if ($scope.paramsReady === true && $scope.operatorsReady === true) {
                    $scope.fields = fieldsFetcher($scope);
                    $scope.originalFields = angular.copy($scope.fields);
                    $scope.filterModel = {};
                    $scope.options = {};
                    $scope.onSubmit = onSubmit;
                }
            });

            function getOperators(scope) {
                LabFilterOperatorsFactory.get(
                    function (success) {
                        var property_names = {
                            name: 'name',
                            value: 'value'
                        };
                        scope.operators = ReportingParameterHelpers.extractOptions(success.comparison_operators, property_names)
                        scope.operatorsReady = true;
                    }
                );
            }

            $scope.$watch('operatorsReady', function () {
                if ($scope.paramsReady === true && $scope.operatorsReady === true) {
                    $scope.fields = fieldsFetcher($scope);
                    $scope.originalFields = angular.copy($scope.fields);
                    $scope.filterModel = {};
                    $scope.options = {};
                    $scope.onSubmit = onSubmit;
                }
            });

            function fieldsFetcher(scope) {
                return [
                    {
                        className: 'layout-row',
                        fieldGroup: [

                            {
                                className: "flex-50",
                                key: 'name',
                                type: 'input',
                                templateOptions: {
                                    label: 'Name of rule',
                                    placeholder: "Provide a name for this rule...",
                                    required: true,
                                }

                            },
                            {

                                className: "flex-50",
                                key: 'threshold.operator',
                                type: 'searchable_select',
                                templateOptions: {
                                    label: 'Rule operator',
                                    required: true,
                                    options: scope.operators
                                }

                            }

                        ]
                    },
                    {
                        className: 'layout-row',
                        fieldGroup: [

                            {
                                className: "flex-50",
                                key: 'threshold.fail_value',
                                type: 'input',
                                templateOptions: {
                                    label: 'Fail value',
                                    required: true,
                                }

                            },
                            {

                                className: "flex-50",
                                key: 'threshold.caution_value',
                                type: 'input',
                                templateOptions: {
                                    label: 'Caution value',
                                    required: true,
                                }

                            }

                        ]
                    },
                    {
                        key: 'formula_definition',
                        type: 'repeatSection',
                        templateOptions: {
                            btnText: 'Add a reporting parameter (ratio) to the calculation',
                            fields: [{

                                className: 'layout-row',
                                fieldGroup: [
                                    {

                                        className: "flex",
                                        key: 'factor_1',
                                        type: 'input',
                                        templateOptions: {
                                            label: 'Multiplier applied to primary parameter',
                                            required: true,
                                            placeholder: 'eg. 1.0'
                                        }

                                    }, {

                                        className: "flex",
                                        key: 'parameter_1',
                                        type: 'searchable_select',
                                        templateOptions: {
                                            label: 'Primary parameter',
                                            required: true,
                                            options: scope.repParams
                                        }

                                    }, {

                                        className: "flex",
                                        type: 'checkbox',
                                        key: 'ratio',
                                        templateOptions: {
                                            label: 'Want this parameter as a ratio against another parameter?'
                                        },
                                        watcher: {
                                            listener: function (field, newValue, oldValue, scope, stopWatching) {
                                                if (newValue === false) {
                                                    if (scope.model.hasOwnProperty('parameter_2')) {
                                                        delete scope.model['parameter_2']
                                                    }
                                                    if (scope.model.hasOwnProperty('factor_2')) {
                                                        delete scope.model['factor_2']
                                                    }
                                                }
                                            }
                                        }

                                    }, {

                                        hideExpression: "!model.ratio",
                                        className: "flex",
                                        key: 'factor_2',
                                        type: 'input',
                                        templateOptions: {
                                            label: 'Multiplier applied to division parameter',
                                            required: true,
                                            placeholder: 'eg. 1.0'
                                        },
                                        expressionProperties: {
                                            "templateOptions.required": "model.ratio",
                                            "templateOptions.disabled": "!model.ratio"
                                        }

                                    }, {

                                        hideExpression: "!model.ratio",
                                        className: "flex",
                                        key: 'parameter_2',
                                        type: 'searchable_select',
                                        templateOptions: {
                                            label: 'Parameter used for division',
                                            required: true,
                                            options: scope.repParams
                                        },
                                        expressionProperties: {
                                            "templateOptions.required": "model.ratio",
                                            "templateOptions.disabled": "!model.ratio"
                                        }

                                    }, {

                                        className: "flex",
                                        key: 'operator',
                                        type: 'searchable_select',
                                        templateOptions: {
                                            label: 'Operator',
                                            required: true,
                                            options: [
                                                {name: '+', value: 'PLUS'},
                                                {name: '-', value: 'MINUS'},
                                                {name: 'x', value: 'MULTIPLY'},
                                                {name: '/', value: 'DIVIDE'},
                                                {name: 'End of formula', value: 'null'}
                                            ]
                                        },
                                        watcher: {
                                            listener: function (field, newValue, oldValue, scope, stopWatching) {
                                                if (newValue === 'null') {
                                                    if (scope.model.hasOwnProperty('operator') && scope.model.operator == 'null') {
                                                        angular.forEach($scope.filterModel.formula_definition, function (subModel, index) {
                                                            if (subModel.operator === 'null') {
                                                                $scope.filterModel.formula_definition = $scope.filterModel.formula_definition.slice(0, index + 1)
                                                            }
                                                        })
                                                    }
                                                }
                                            }
                                        }
                                    }

                                ]
                            }]
                        }
                    }
                ]
            }

        }]);

})();

