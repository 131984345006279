angular.module('labApp').controller('StorageNetExportController',
    function ($scope, $khMessage, $mdDialog, $http,
              UserTeamsService,
              ErrorLoggingService, FarmConstantsService,
              FarmFiltersService) {

        function getFields() {
            return [
                {
                    className: 'layout-row',
                    fieldGroup: [
                        {
                            key: 'report',
                            type: 'searchable_select',
                            className: "flex-50",
                            templateOptions: {
                                label: 'Report',
                                required: true,
                                focus: true,
                                options: [
                                    {name: 'Field Fixed', value: 'field_fixed'},
                                    {name: 'Field Results', value: 'field_results'},
                                    {name: 'Store Fixed', value: 'store_fixed'},
                                    {name: 'Store Results', value: 'store_results'}
                                ]
                            }
                        },
                        {
                            className: "flex-50",
                            type: "select",
                            key: "season",
                            templateOptions: {
                                required: true,
                                label: "Growing Season",
                                options: [
                                ]
                            },
                            controller: function ($scope) {
                                FarmConstantsService.promise.then(function(result) {
                                    $scope.options.templateOptions.options = result.planting_season_list;
                                });
                            }
                        }
                    ]
                },
                {
                    className: 'layout-row',
                    fieldGroup: [
                        {
                            key: 'teams',
                            type: 'searchable_select',
                            className: "flex-100",
                            templateOptions: {
                                label: 'Grower(s)',
                                required: true,
                                multiple: true,
                                options: []
                            },
                            controller: function ($scope) {
                                UserTeamsService.query().then(function(teams) {
                                    $scope.options.templateOptions.options = _.map(teams, function(team) {
                                       return {
                                           name: team.name,
                                           value: team.slug
                                       }
                                    });
                                });
                            }
                        }
                    ]
                },
                {
                    className: 'layout-row',
                    fieldGroup: [
                        {
                            className: "flex-50",
                            type: "datepicker",
                            key: "start_date",
                            optionsTypes: ['pastDate'],
                            templateOptions: {
                                required: true,
                                label: "Start date",
                                format: "DD-MM-YYYY"
                            }
                        },
                        {
                            className: "flex-50",
                            type: "datepicker",
                            key: "end_date",
                            optionsTypes: ['pastDate'],
                            templateOptions: {
                                required: true,
                                label: "End date",
                                format: "DD-MM-YYYY"
                            }
                        }
                    ],
                    hideExpression: '!model.report || model.report==="field_fixed" || model.report==="store_fixed"',
                }
            ]
        }

        $scope.storageNetExportFields = getFields();
        $scope.storageNetExportSpec = {
            start_date: new Date(),
            end_date: new Date(),
            season: FarmFiltersService.getGrowingSeason()
        };
        $scope.generateFile = generateFile;

        function collateParams(formData) {
            return {
                reportType: formData.report,
                start_date: moment(formData.start_date).format('DD-MM-YYYY'),
                end_date: moment(formData.end_date).format('DD-MM-YYYY'),
                teams: formData.teams.join(','),
                season: formData.season
            };
        }

        function downloadStorageNetReport(params) {
            var url = "/api/v1.0/lab/external/pepsico/storage-net/report-type/" + params.reportType + "/";
            $http.post(url, {
                reportType: params.reportType,
                teams: params.teams,
                season: params.season,
                start_date: params.start_date,
                end_date: params.end_date
            }).then(function (success) {
                $khMessage.show("Export request successfully submitted. Results will be emailed to you once prepared.",
                    5000);
                $mdDialog.hide()
            }
            , function (error) {
                $khMessage.show("Failed to generate report - please contact support", 5000);
                ErrorLoggingService.log("Failed to generate StorageNet report: " + error.data);
            });
        }

        function generateFile(formData) {
            var params = collateParams(formData);
            downloadStorageNetReport(params);
        }
    });