angular.module("labApp").config(function ($routeProvider, $resourceProvider, $translateProvider) {
    $resourceProvider.defaults.stripTrailingSlashes = false;

    $translateProvider.translations('en', {
        action: 'Action',
        actions: 'Actions',
        add: 'Add',
        add_new_result: 'Add Result',
        add_reporting_parameter: 'Add Reporting Parameter',
        add_specification: 'Add Specification',
        add_a_spec_message: 'Please click the button in the header to begin adding a specification for this assessment',
        all_teams_selected_message: 'Please select a specific team and try again',
        assessed: 'Assessed',
        assessment: 'Assessment',
        assessments: 'Assessments',
        assessment_attachments: 'Assessment attachments',
        assessment_classification: 'Assessment Classification',
        assessment_category: 'Assessment Category',
        assessment_crop: 'Assessment Crop',
        assessment_description: 'Assessment Description',
        assessment_name: 'Assessment Name',
        associated_field_location_names: "Associated Field Sample Locations",
        back: 'back',
        cancel: 'Cancel',
        created_successfully: 'Created successfully',
        crop: 'Crop',
        crop_detail: 'Crop',
        customer: 'Customer',
        data_loading: 'Please wait: your data is loading',
        data_loading_error: 'Sorry, there has been an error retrieving data. Please contact support if this problem persists.',
        delete: 'Delete',
        delete_sample: 'Delete Sample',
        delete_result: 'delete result',
        delete_result_parameter: 'delete parameter',
        delete_failure: 'Deletion failed',
        deleted_successfully: 'Deleted successfully',
        delete_specification: 'Delete specification',
        edit: 'Edit',
        edit_results: 'Edit Results',
        evaluation_type: 'Evaluation_type',
        export_to_csv: 'Export to CSV',
        export_to_storage_net: 'Export to StorageNet',
        farm: 'Farm',
        failed_to_create: 'Failed to create',
        failed_to_delete: 'Failed to delete',
        failed_to_update: 'Failed to update',
        field: 'Field',
        field_crop: 'Field Crop',
        field_name: 'Field Name',
        field_sample_locations: 'Field Sample Locations',
        filter_by_assessment_category: 'Filter by assessment category',
        filter_by_crop: 'Filter by crop',
        filter_by_farm: 'Filter by farm',
        filter_by_field: 'Filter by field',
        filter_by_field_crop_red: 'Filter by field crop reference',
        filter_by_formula_contents: 'Filter by formula contents',
        filter_by_operator: 'Filter by operator',
        filter_by_parameter_name: 'Filter by parameter name',
        filter_by_required_status: 'Filter by required status',
        filter_by_sample_date: 'Filter by sample date',
        filter_by_sample_reference: 'Filter by sample reference',
        filter_by_threshold: 'Filter by threshold name',
        filter_by_store: 'Filter by store',
        filter_by_store_lot: 'Filter by store lot',
        filter_by_store_name: 'Filter by store name',
        filter_by_value_type: 'Filter by value type',
        filter_by_variety: 'Filter by variety',
        formula_display: 'Formula Display',
        lab_navigation: 'Lab navigation',
        location: 'Location',
        location_id: 'Location ID',
        message_select_a_season: 'Please select a single season from the drop-down',
        name: 'name',
        nav_message_back_to_assessments_summary: 'Return to Assessments Summary',
        nav_message_back_to_assessment: 'Return to Assessment',
        negative_assessed_status: 'Negative assessed status',
        new_assessment: 'New Assessment',
        new_assessment_specification: 'New Assessment Specification',
        new_assessment_specification_filter: 'New Specification Threshold',
        new_reporting_parameter: 'New Reporting Parameter',
        new_results: 'New Results',
        next_page: 'Next page',
        no_field_samples_message: 'No field samples have been created for this filter combination',
        no_field_sample_locations_message: 'No field sample locations have been created for this filter combination',
        no_reporting_results_message: 'There are no reporting results for this filter combination',
        no_store_samples_message: 'No store samples have been created for this filter combination',
        no_store_sample_locations_message: 'No store sample locations have been created for this filter combination',
        no_specs_message: 'No specifications have been created for this assessment',
        no_spec_without_rps_message: 'Specifications cannot be added until Reporting Parameters have been created for this assessment',
        no_spec_threshold_message: 'No specification thresholds have been created yet',
        notifications: 'notifications',
        notification_assessment_placeholder: 'Select assessment for notifications',
        notification_lists_title: 'Notifications for Lab Assessment Results',
        parameter_name: 'Parameter Name',
        parameter_definition: 'Parameter Definition',
        placeholder_select_users: 'Select users',
        positive_assessed_status: 'Positive assessed status',
        previous_page: 'Previous page',
        recorded_by: 'Recorded by',
        recorded_new_results: 'Record new results',
        recording: 'Recording',
        recording_date: 'Recording date',
        reported_value: 'Reported Value',
        result: 'Result',
        results: 'Results',
        results_feed: 'Results feed',
        results_feed_banner: 'Results from the last 10 days',
        reports: 'Reports',
        required: 'Required',
        return_to_sample_summary: 'return to sample summary',
        sample: 'Sample',
        samples: 'Samples',
        samples_and_results: 'Samples and results',
        sample_assessments_log: 'Sample Assessments Log',
        sample_date: 'Sample date',
        sample_details: 'Sample Detail',
        sample_location: 'Sample location',
        sample_locations: 'Sample Locations',
        sample_summary: 'Sample Summary',
        sample_type: 'Sample type',
        setup: 'Setup',
        specification_rule: 'Specification Rule',
        status: 'Status',
        store_lot: 'Store Lot',
        store: 'Store',
        store_name: 'Store',
        store_sample_locations: 'Store Sample Locations',
        save: 'Save',
        title_assessment_summary: 'Assessment Summary',
        title_assessment_specifications: 'Assessment Specifications',
        title_assessment_parameters: 'Assessment Parameters',
        title_reporting_parameters: 'Reporting Parameters',
        title_filter_caution_value: 'Caution Value',
        title_filter_definition: 'Threshold Definition',
        title_filter_fail_value: 'Failure Value',
        title_formula_string: 'Formula',
        title_list_for_immediate_notification: 'Immediate Notification',
        title_list_for_round_up_notification: 'Round-Up Notification',
        title_operator: 'Operator',
        title_specification_threshold_name: 'Threshold Name',
        title_specification_thresholds: 'Specification Thresholds',
        type: 'type',
        unit_name: 'Unit Name',
        updated_successfully: 'Updated successfully',
        value: 'value',
        value_type: 'Value Type',
        variety: 'Variety',
        view: 'View',
        view_activity: 'View Activity',
        view_specification_details: 'View specification details',
        wait_for_data: 'Please wait whilst your data is loading',
    });

    $routeProvider
        .when("/", {
            controller: "ResultsFeedController",
            templateUrl: "/static/assets/khapps/lab/results_feed/results_feed_page.html"
        })

        // Samples & Results
        .when("/samples/", {
            controller: "SamplesListController",
            templateUrl: "/static/assets/khapps/lab/samples_and_results/sample_list/sample_list.html"
        })
        .when("/samples/:sample_slug", {
            controller: "SamplesDetailController",
            templateUrl: "/static/assets/khapps/lab/samples_and_results/sample_detail/sample_detail.html"
        })
        .when("/results/", {
            controller: "ResultsListController",
            templateUrl: "/static/assets/khapps/lab/samples_and_results/assessment_result_list/result_list.html"
        })

        // Reports
        .when("/reports", {
            controller: "ReportController",
            templateUrl: "/static/assets/khapps/lab/reports/Reports.html"
        })

        // Setup
        .when("/sample-locations", {
            templateUrl: "/static/assets/khapps/lab/sample_locations/sample_location.html"
        })
        .when("/assessments/", {
            controller: "AssessmentsShowController",
            templateUrl: "/static/assets/khapps/lab/assessments/assessment_list/AssessmentList.html"
        })
        .when("/assessments/add", {
            controller: "AssessmentAddEditController",
            templateUrl: "/static/assets/khapps/lab/assessments/assessment_add_edit/AssessmentForm.html"
        })
        .when("/assessments/:assessment_config_slug", {
            controller: "AssessmentDetailController",
            templateUrl: "/static/assets/khapps/lab/assessments/assessment_detail/AssessmentDetail.html"
        })
        .when("/assessments/:assessment_config_slug/specifications/:specification_slug", {
            controller: "AssessmentSpecificationDetailController",
            templateUrl: "/static/assets/khapps/lab/assessments/assessment_specification/assessment_specification_detail.html"
        })
        .when("/distribution-lists/", {
            controller: "LabDistributionListController",
            templateUrl: "/static/assets/khapps/lab/notifications/lab_distribution_list.html"
        })
        .otherwise({
            redirectTo: '/'
        });
});
