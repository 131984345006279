(function () {

    'use strict';

    angular.module("labApp").controller('AssessmentAddEditController', [
        '$scope',
        '$location',
        '$timeout',
        'AssessmentFactory',
        'AssessmentParameterFactory',
        '$khMessage',
        '$filter',
        '$mdDialog',
        function ($scope,
                  $location,
                  $timeout,
                  AssessmentFactory,
                  AssessmentParameterFactory,
                  $khMessage,
                  $filter,
                  $mdDialog) {

            $scope.metaModel = {};
            $scope.assessmentFields = assessmentFieldsFetcher($scope);

            $scope.paramModel = {};
            $scope.paramOptions = {};
            $scope.paramFields = paramFieldsFetcher($scope);
            $scope.originalParamFields = angular.copy($scope.paramFields);

            $scope.onSubmit = onSubmit;
            $scope.closeDialog = closeDialog;

            function onSubmit() {
                AssessmentFactory.save($scope.metaModel, function (success) {
                    var assessmentParams = angular.copy($scope.paramModel.form);
                    angular.forEach(assessmentParams, function (value, key) {
                        value.assessment_configurator = success.assessment_config_slug;
                    });
                    AssessmentParameterFactory.save(assessmentParams, function (paramSuccess) {
                        $khMessage.show($filter('translate')('created_successfully'), 5000);
                        $timeout(
                            function () {
                                $location.path('/assessments/' + success.assessment_config_slug)
                            }, 3000
                        );

                    }, function (error) {
                        console.log(error)
                        $khMessage.show($filter('translate')('failed_to_create'), 5000);
                        $timeout(
                            function () {
                                $location.path('/')
                            }, 3000
                        );
                    });


                }, function (error) {
                    $khMessage.show($filter('translate')('failed_to_create'), 5000);
                    $timeout(
                        function () {
                            $location.path('/assessments/')
                        }, 3000
                    );

                });

            }

            function closeDialog() {
                $mdDialog.hide();
            }

            function assessmentFieldsFetcher(scope) {
                return [
                    {
                        className: 'layout-row',
                        fieldGroup: [
                            {
                                key: 'name',
                                type: 'input',
                                className: "flex-50",
                                templateOptions: {
                                    label: 'Name of the assessment',
                                    required: true,
                                    focus: true
                                }
                            },
                            {
                                key: 'description',
                                type: 'input',
                                className: "flex-50",
                                templateOptions: {
                                    label: 'Description of the assessment',
                                    required: false
                                }
                            }

                        ]
                    },
                    {
                        className: 'layout-row',
                        fieldGroup: [
                            {
                                key: 'crop',
                                type: 'searchable_select',
                                className: "flex-50",
                                templateOptions: {
                                    label: 'Assessment crop',
                                    required: true,
                                    options: [],
                                    valueProp: 'crop_id',
                                    labelProp: 'crop_name',
                                },
                                controller: function ($scope, CropListFactory) {
                                    CropListFactory.query(
                                        {},
                                        function (result) {
                                            console.log(result);
                                            $scope.crop_count = result.length;
                                            $scope.to.options = result
                                        },
                                        function (error) {
                                            console.log("Error: ", error);
                                        }
                                    )
                                }
                            },
                            {
                                key: 'assessment_classification',
                                type: 'searchable_select',
                                className: "flex-50",
                                templateOptions: {
                                    label: 'Assessment category',
                                    required: true,
                                    options: [],
                                    valueProp: 'classification_name',
                                    labelProp: 'classification_name'
                                },
                                controller: function ($scope, AssessmentClassification) {
                                    AssessmentClassification.query(
                                        {},
                                        function (result) {
                                            $scope.to.options = result
                                        }, function (error) {
                                            console.log("Error: ", error)
                                        }
                                    )
                                }

                            }
                        ]
                    }
                ]
            }

            function paramFieldsFetcher(scope) {
                return [
                    {
                        key: 'form',
                        type: 'repeatSection',
                        templateOptions: {
                            btnText: 'Add a parameter to the assessment',
                            fields: [
                                {
                                    className: 'layout-row',
                                    fieldGroup: [
                                        {

                                            className: "flex-50",
                                            key: 'form_label',
                                            type: 'input',
                                            templateOptions: {
                                                label: 'Name of data field',
                                                required: true,
                                                focus: true
                                            }
                                        },
                                        {
                                            className: "flex-50",
                                            key: 'value_type',
                                            type: 'searchable_select',
                                            templateOptions: {
                                                label: 'Type of value',
                                                required: true,
                                                options: [
                                                    {name: 'Numeric only', value: 'NUM'},
                                                    {name: 'Text / Mixture / Not consistent', value: 'STR'},
                                                    {name: 'Date', value: 'DAT'},
                                                    {name: 'Time', value: 'TIM'}
                                                ]
                                            }

                                        }
                                    ]
                                },
                                {
                                    className: 'layout-row',
                                    fieldGroup: [
                                        {
                                            className: "flex-50",
                                            key: 'default_num',
                                            type: 'input',
                                            hideExpression: 'model.value_type !== "NUM"',
                                            templateOptions: {
                                                label: 'Default value',
                                                required: false,
                                            }
                                        },
                                        {
                                            className: "flex-50",
                                            key: 'default_str',
                                            type: 'input',
                                            hideExpression: 'model.value_type !== "STR"',
                                            templateOptions: {
                                                label: 'Default value',
                                                required: false,
                                            }

                                        },
                                        {
                                            className: "flex-50",
                                            key: 'unit',
                                            type: 'searchable_select',
                                            defaultValue: 'blank',
                                            hideExpression: 'model.value_type !== "NUM"',
                                            templateOptions: {
                                                label: 'Measurement units',
                                                required: true,
                                                options: []
                                            },
                                            controller: function ($scope, UnitListFactory) {
                                                UnitListFactory.query(
                                                    {},
                                                    function (result) {
                                                        $scope.to.options = result;
                                                        $scope.to.options.push({name: 'None', value: 'blank'});
                                                    }, function () {
                                                        console.log("Error: ", error);
                                                    }
                                                )
                                            }
                                        }

                                    ]
                                },
                                {
                                    className: 'layout-row',
                                    fieldGroup: [
                                        {
                                            className: "flex-50",
                                            key: 'required',
                                            type: 'checkbox',
                                            defaultValue: false,
                                            templateOptions: {
                                                label: 'Is a response always required for this field?',
                                            }
                                        }
                                    ]
                                }
                            ]
                        }
                    }
                ]
            }


        }]);

})();