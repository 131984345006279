angular.module("labApp").factory("AssessmentConfigParametersFactory", ["$resource", function ($resource) {
    return $resource('api/v1.0/lab/assessment-parameters/:slug/', {slug: '@slug'}, {
        query: {
            isArray: true
        },
        save: {
            method: 'POST',
            isArray: true
        },
        update: {
            method: 'PUT'
        },
        get: {
            method: 'GET',
            isArray: true
        }
    });
}]);