angular.module("labApp").factory("StSampleFromIdFactory", ["$resource", function ($resource) {
    return $resource('api/v1.0/lab/st-sample-from-id/:slug/', {slug: '@slug'}, {
        query: {
            isArray: true
        },
        save: {
            method: 'POST'
        },
        update: {
            method: 'PUT'
        },
        get: {
            method: 'GET'
        }
    });
}]);
