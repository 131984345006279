angular.module("labApp").factory("ProcessorFirmFactory", ["$resource", function ($resource) {
    return $resource('/api/v1.0/enterprise/firm/platform-wide-firms/?firm_type=Processor', {
        query: {
            method: 'GET'
        },
        get: {
            method: 'GET'
        }
    });
}]);
