angular.module("labApp").factory("FieldStylingHelpersFactory", [function () {
    return {
        classes: {
            'c12-12-12': 'col-lg-12 col-md-12 col-sm-12',
            'c6-6-6': 'col-lg-6 col-md-6 col-sm-6',
            'c4-4-4': 'col-lg-4 col-md-4 col-sm-4',
            'c3-3-3': 'col-lg-3 col-md-3 col-sm-3',

            'c6-6-12': 'col-lg-6 col-md-6 col-sm-12',
            'c4-6-12': 'col-lg-4 col-md-6 col-sm-12',

            'c4/2-6-12': 'col-lg-4 col-lg-offset-2 col-md-6 col-sm-12',
        }
    }
}]);

