(function (angular) {
    "use strict";
    angular.module('labApp')
        .factory('FCSampleFactory', Resource);

    Resource.$inject = ['$resource', 'GenericResourceInterface'];

    function Resource($resource, GenericResourceInterface) {
        //////////////////////////////////////
        // Variable declarations
        //////////////////////////////////////
        // List of fields to extract from input data as valid query parameters
        const IDENTIFIER_KEY = "slug";
        const FILTER_FIELDS = {
            team: "team",
            growing_season: "season"
        };

        //////////////////////////////////////
        // API Resource
        //////////////////////////////////////
        var url = "api/v1.0/lab/fc-samples/:slug/";
        const RESOURCE = $resource(
            url, {slug: '@slug'},
            {
                query: {
                    method: 'GET',
                    isArray: true
                },
                get: {
                    method: 'GET'
                },
                save: {
                    method: 'POST'
                },
                update: {
                    method: 'PUT'
                },
                delete: {
                    method: 'DELETE'
                }
            }
        );

        return new GenericResourceInterface(RESOURCE, FILTER_FIELDS, IDENTIFIER_KEY);

    }

})(angular);
