(function () {

    'use strict';

    angular.module("labApp").controller('AssessmentSpecificationDetailController', [
        '$scope',
        '$rootScope',
        '$routeParams',
        '$filter',
        '$location',
        '$mdDialog',
        'AssessmentSpecificationFactory',
        'AssessmentSpecificationFiltersFactory',
        'NgTableParams',
        function ($scope,
                  $rootScope,
                  $routeParams,
                  $filter,
                  $location,
                  $mdDialog,
                  AssessmentSpecificationFactory,
                  AssessmentSpecificationFiltersFactory,
                  NgTableParams) {

            $rootScope.page_id = "labanalytics";
            $rootScope.sub_app_page_id = "labanalytics_setup";
            $rootScope.currentNavItem = "assessments";
            $scope.assessment_configurator = $routeParams.assessment_config_slug;
            $scope.specification_slug = $routeParams.specification_slug;
            $scope.deleteFilter = deleteFilter;
            $scope.showFilterForm = showFilterForm;
            $scope.convertOperator = convertOperator;

            getAssessmentFilters();
            getAssessmentSpecifications();
            $scope.assessmentSpecificationCards = [
                {
                    kh_card_content_template: '/static/assets/khapps/lab/assessments/assessment_specification/assessment_specification_detail_nav_card.html',
                    card_flex: 100
                },
                {
                    card_title: $filter('translate')('title_specification_thresholds'),
                    kh_card_toolbar_filters_template: '/static/assets/khapps/lab/assessments/assessment_specification/assessment_specification_filters_toolbar.html',
                    kh_card_content_template: '/static/assets/khapps/lab/assessments/assessment_specification/assessment_specification_detail_card.html',
                    card_flex: 100
                }
            ];
            $scope.searchThreshold = {
                name: {
                    id: 'text',
                    placeholder: $filter('translate')('filter_by_threshold')
                }
            };
            $scope.searchFormula = {
                formula_string: {
                    id: 'text',
                    placeholder: $filter('translate')('filter_by_formula_contents')
                }
            };
            $scope.searchOperator = {
                operator: {
                    id: 'text',
                    placeholder: $filter('translate')('filter_by_operator')
                }
            };

            $scope.$on('filterSaved', function (event, data) {
                $scope.filters.push(data);
            });

            var updatedFilterValues = $scope.$on('filter:selections_updated', function () {
                redirectToAssessmentList()
            });
            $scope.$on('$destroy', function () {
                updatedFilterValues();
            });

            function showFilterForm() {
                $mdDialog.show({
                    templateUrl: '/static/assets/khapps/lab/assessments/assessment_specification/specification_filter_form.html',
                    controller: 'SpecificationFilterFormController',
                    scope: $scope,
                    preserveScope: true,
                    locals: {}
                }).then(function (data) {
                });
            }

            function convertOperator (operatorString) {
                if (operatorString === 'eq') {
                    return 'Equal to'
                }
                if (operatorString === 'ne') {
                    return 'Not equal to'
                }
                if (operatorString === 'le') {
                    return 'Less than or equal to'
                }
                if (operatorString === 'lt') {
                    return 'Less than'
                }
                if (operatorString === 'ge') {
                    return 'Greater than or equal to'
                }
                if (operatorString === 'gt') {
                    return 'Greater than'
                }
                return ''
            };

            function getAssessmentSpecifications() {
                AssessmentSpecificationFactory.get(
                    {
                        assessment: $scope.assessment_configurator,
                        specification: $scope.specification_slug
                    }, function (specification_data) {
                        $scope.specification = specification_data;
                    }, function (error) {
                        console.log(error)
                    }
                );
            }

            function getAssessmentFilters() {
                AssessmentSpecificationFiltersFactory.query(
                    {
                        assessment: $scope.assessment_configurator,
                        specification: $scope.specification_slug
                    }, function (filters_data) {
                        $scope.filters = filters_data;
                        loadTableData('specificationFilters', $scope.filters, NgTableParams);
                    }, function (error) {
                        console.log(error)
                    }
                );
            }

            function redirectToAssessmentList() {
                $location.path('/assessments/');
            }

            function extractDeletedFilter(table_params, filter_slug) {
                var removeIndex = table_params.map(function (element) {
                    return element.lab_spec_filter_slug
                }).indexOf(filter_slug);
                ~removeIndex && table_params.splice(removeIndex, 1);
            }

            function deleteFilter (filter_slug, filters) {
                AssessmentSpecificationFiltersFactory.remove(
                    {
                        assessment: $scope.assessment_configurator,
                        specification: $scope.specification_slug,
                        filter_slug: filter_slug
                    },
                    function (success) {
                        extractDeletedFilter(filters, filter_slug);
                    },
                    function (error) {
                        console.log(error)
                    }
                )
            }

            function loadTableData(scopeObject, data, NgTableParams) {
                $scope[scopeObject] = new NgTableParams({}, {dataset: data});
            }

        }]);

})();