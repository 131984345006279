angular.module('KisanSatApp')
    .run(function ($http, $rootScope, $routeParams, $location, $mdDialog, UserSettingsFactory,
                   DateTimeHelperFactory, PlatformSettingsService) {
        $http.defaults.xsrfCookieName = 'csrftoken';
        $http.defaults.xsrfHeaderName = 'X-CSRFToken';

        PlatformSettingsService.promise.then(function (data) {
            $rootScope.platformSettingsAPIData = data;
            $rootScope.khgeoserver = data.KISANSAT_UAVGEOSERVER_URL;
            $rootScope.ksatgeoserver = data.KISANSAT_SATGEOSERVER_URL;
            $rootScope.khauthserver = data.KH_INFRASTRUCTURE_AUTH_URL;
            $rootScope.khmapprintserver = data.KISANSAT_PRINTSERVER_URL;
        });
        UserSettingsFactory.get(function (result) {
            $rootScope.user_settings = result;
            $rootScope.userSettingDateFormat = DateTimeHelperFactory.convertDateTimeFormatFromPythonToAngular($rootScope.user_settings.DEFAULT_USER_READABLE_DATE_FORMAT);
            $rootScope.userSettingDateTimeFormat = DateTimeHelperFactory.convertDateTimeFormatFromPythonToAngular($rootScope.user_settings.DEFAULT_USER_READABLE_DATE_TIME_FORMAT);
            $rootScope.userSettingDateFormat = DateTimeHelperFactory.convertDateTimeFormatFromPythonToAngular($rootScope.user_settings.DEFAULT_USER_READABLE_DATE_FORMAT);


            $rootScope.userSettingDateFormatMoment = DateTimeHelperFactory.convertDateTimeFormatFromPythonToMoment($rootScope.user_settings.DEFAULT_USER_READABLE_DATE_FORMAT);
            $rootScope.userSettingDateTimeFormatMoment = DateTimeHelperFactory.convertDateTimeFormatFromPythonToMoment($rootScope.user_settings.DEFAULT_USER_READABLE_DATE_TIME_FORMAT);
            $rootScope.userSettingDateFormatMoment = DateTimeHelperFactory.convertDateTimeFormatFromPythonToMoment($rootScope.user_settings.DEFAULT_USER_READABLE_DATE_FORMAT);

        });
    });

