angular.module("labApp").factory("LabFormHelpers", [function () {
    var formHelpers = {

        showModal: function (form_class_name) {
            formHelpers.showForm(form_class_name);
            $('#modal').modal('show');
        },

        showForm: function (form_class_name) {
            $('.modal-wrapper').css('display', 'none');
            $(form_class_name).css('display', 'block');
        },

        hideModals: function () {
            $('.modal-wrapper').css('display', 'none');
            $('#modal').modal('hide');
        }

    };
    return formHelpers
}]);