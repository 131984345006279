(function () {

    'use strict';

    angular.module("labApp").controller('AssessmentSpecificationFormController', [
        'ac_slug',
        '$scope',
        '$location',
        '$routeParams',
        '$timeout',
        'AssessmentParameterFactory',
        'FieldStylingHelpersFactory',
        'ReportingParameterHelpers',
        'AssessmentConfigParametersFactory',
        'ReportingParametersFactory',
        'ProcessorFirmFactory',
        'FmsConstantsFactory',
        'LabFormHelpers',
        'AssessmentSpecificationFactory',
        '$mdDialog',
        '$khMessage',
        '$filter',
        function (ac_slug,
                  $scope,
                  $location,
                  $routeParams,
                  $timeout,
                  AssessmentParameterFactory,
                  FieldStylingHelpersFactory,
                  ReportingParameterHelpers,
                  AssessmentConfigParametersFactory,
                  ReportingParametersFactory,
                  ProcessorFirmFactory,
                  FmsConstantsFactory,
                  LabFormHelpers,
                  AssessmentSpecificationFactory,
                  $mdDialog,
                  $khMessage,
                  $filter) {

            $scope.processorsReady = false;
            $scope.destinationsReady = false;
            $scope.ac_slug = ac_slug;
            $scope.onSumbit = onSubmit;
            $scope.closeDialog = closeDialog;

            getProcessors($scope);
            getDestinations($scope);

            function closeDialog() {
                $mdDialog.hide();
            }

            function getProcessors(scope) {
                ProcessorFirmFactory.get(
                    function (success) {
                        var property_names = {
                            name: 'firm_name',
                            value: 'firm_slug'
                        };
                        scope.processors = ReportingParameterHelpers.extractProcessors(
                            success.features,
                            property_names
                        );
                        scope.processorsReady = true;
                    }, function (error) {
                        console.log("Error: ", error);
                    }
                )
            }

            function getDestinations(scope) {
                FmsConstantsFactory.get(
                    function (success) {
                        var optionsArray = [];
                        angular.forEach(success.destinations, function (element, index) {
                            optionsArray.push({
                                name: element.name,
                                value: element.value
                            })
                        });
                        optionsArray.push({name: 'None', value: null});
                        scope.destinations = optionsArray;
                        scope.destinationsReady = true;
                    }, function (error) {
                        console.log("Error: ", error);
                    }
                )
            }

            function onSubmit() {
                $scope.saveObject = ReportingParameterHelpers.prepareSpecificationForSaving(
                    angular.copy($scope.specModel),
                    $scope.ac_slug
                );
                AssessmentSpecificationFactory.save(
                    $scope.saveObject,
                    function (success) {
                        $khMessage.show($filter('translate')('created_successfully'), 5000);
                        $mdDialog.hide();
                        $scope.specModel = {};
                        $scope.$emit('specificationSaved', success.data);
                    }, function (error) {
                        $khMessage.show($filter('translate')('failed_to_create'), 5000);
                    }
                )
            }

            $scope.$watch('processorsReady', function () {
                if ($scope.processorsReady === true && $scope.destinationsReady === true) {
                    $scope.fields = fieldsFetcher($scope);
                    $scope.originalFields = angular.copy($scope.fields);
                    $scope.specModel = {};
                    $scope.options = {};
                    $scope.onSubmit = onSubmit;
                }
            });
            $scope.$watch('destinationsReady', function () {
                if ($scope.processorsReady === true && $scope.destinationsReady === true) {
                    $scope.fields = fieldsFetcher($scope);
                    $scope.originalFields = angular.copy($scope.fields);
                    $scope.specModel = {};
                    $scope.options = {};
                    $scope.onSubmit = onSubmit;
                }
            });

            function fieldsFetcher(scope) {
                return [
                    {
                        className: 'flex',
                        key: 'evaluation_type',
                        type: 'searchable_select',
                        templateOptions: {
                            label: 'Overall evaluation method',
                            required: true,
                            options: [
                                {name: 'Any - assessment fails if any single rule fails', value: 'Any'},
                                {name: 'All - assessment fails only if all rules fail', value: 'All'}
                            ]
                        }
                    },
                    {
                        className: 'flex',
                        key: 'customer',
                        type: 'searchable_select',
                        templateOptions: {
                            label: 'Is this specification specific to a customer?',
                            required: false,
                            options: scope.processors
                        }
                    }
                ]
            }

        }]);

})();

